import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import kebabCase from 'lodash/kebabCase'
import type { SAMLConfiguration } from 'login/types'
import MultiSSOButton from './MultiSSOButton'

const styles = makeStyles({
  text: { marginBottom: '16px', fontSize: '14px', color: 'white' },
})

export const MultiSSOButtons = ({
  hideLocalLogin,
  samlConfigurations,
}: {
  hideLocalLogin?: boolean
  samlConfigurations: SAMLConfiguration[]
}): JSX.Element => {
  const classes = styles()

  return (
    <Box
      alignItems="center"
      data-testid="multi-sso-buttons-container"
      display="flex"
      flexDirection="column"
      mt={5.25}
    >
      {samlConfigurations.length > 0 && (
        <Typography className={classes.text}>
          {hideLocalLogin ? 'Choose your' : 'Or choose another'} login method
        </Typography>
      )}
      {samlConfigurations.map((config) => (
        <MultiSSOButton
          key={config.id}
          data-testid={`multi-sso-button-${kebabCase(config.id)}`}
          iconURL={config.iconURL}
          redirectURL={config.redirectURL}
          text={config.name}
        />
      ))}
    </Box>
  )
}

export default MultiSSOButtons

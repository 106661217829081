// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { Component } from 'react'
import InstructionsText from './InstructionsText'
import SubmitButton from './SubmitButton'
import ThemeProvider from './ThemeProvider'

export default class SsoLogout extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    multiSamlEnabled: PropTypes.bool,
    paths: PropTypes.object.isRequired,
  }

  /* @ts-expect-error auto-src: noflow */
  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    /* @ts-expect-error auto-src: noflow */
    window.location.pathname = this.props.paths.newSession
  }

  render() {
    return (
      // @ts-expect-error Props are currently not typed with TS
      <ThemeProvider multiSamlEnabled={this.props.multiSamlEnabled}>
        {/* @ts-expect-error Props are currently not typed with TS */}
        <InstructionsText multiSamlEnabled={this.props.multiSamlEnabled}>
          Signed out successfully.
        </InstructionsText>
        <form onSubmit={this.handleClick}>
          <SubmitButton
            label="SIGN IN"
            /* @ts-expect-error Props are currently not typed with TS */
            multiSamlEnabled={this.props.multiSamlEnabled}
          />
        </form>
      </ThemeProvider>
    )
  }
}

import { Component } from 'react'
import type { LoginPaths } from 'login/types'
import NotificationsCenter from './NotificationsCenter'
import UnlockForm from './UnlockForm'
import ThemeProvider from './ThemeProvider'

type Props = {
  multiSamlEnabled: boolean
  paths: LoginPaths
  serverFeedback: string | null | undefined
}

export default class UnlockAccount extends Component<Props> {
  handleSubmit = (): void => {
    /* @ts-expect-error auto-src: noflow */
    this.notificationsRef.openProgressDialog()
  }

  render(): JSX.Element {
    const { multiSamlEnabled, serverFeedback, ...rest } = this.props

    return (
      <ThemeProvider multiSamlEnabled={multiSamlEnabled}>
        <NotificationsCenter
          ref={(notifications) => {
            /* @ts-expect-error auto-src: noflow */
            this.notificationsRef = notifications
          }}
          dialogText="Please wait..."
          multiSamlEnabled={multiSamlEnabled}
          serverFeedback={serverFeedback}
        />

        <UnlockForm
          {...rest}
          ref={(form) => {
            /* @ts-expect-error auto-src: noflow */
            this.formRef = form
          }}
          multiSamlEnabled={multiSamlEnabled}
          onSubmit={this.handleSubmit}
          paths={this.props.paths}
        />
      </ThemeProvider>
    )
  }
}

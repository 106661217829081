// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { stackedMenuButton } from '../styles/specificStyles'
import { buttonStyles } from '../styles/styleProps'
import InstructionsText from './InstructionsText'
import ThemeProvider from './ThemeProvider'
import SubmitButton from './SubmitButton'

type Props = {
  authorizedPaths: any
  multiSamlEnabled?: boolean
}
const useButtonStyles = makeStyles({
  root: { ...stackedMenuButton, ...buttonStyles },
})
const useStyles = makeStyles({
  instructions: {
    paddingBottom: '10px !important',
  },
})

const UnauthorizedNavigation = ({
  authorizedPaths,
  multiSamlEnabled = false,
}: Props) => {
  const buttonClasses = useButtonStyles()
  const classes = useStyles()

  return (
    <ThemeProvider multiSamlEnabled={multiSamlEnabled}>
      <InstructionsText
        className={classes.instructions}
        multiSamlEnabled={multiSamlEnabled}
      >
        Access Denied
      </InstructionsText>
      {Object.keys(authorizedPaths).map((label, index) =>
        multiSamlEnabled ? (
          <SubmitButton
            key={index}
            href={authorizedPaths[label]}
            label={label}
            multiSamlEnabled
            style={{ margin: '16px 0' }}
            type="button"
          />
        ) : (
          <Button
            key={index}
            classes={buttonClasses}
            fullWidth
            href={authorizedPaths[label]}
            variant="contained"
          >
            {label}
          </Button>
        ),
      )}
    </ThemeProvider>
  )
}

export default UnauthorizedNavigation
